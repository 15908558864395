.blackcanvas {
  background: black;
  width: 100%;
  height: 1000px;
  justify-content: center;
  display: flex;
}

.purple {
  color: rgb(185, 5, 154, 1);
}

.blackcanvastext {
  color: white;
  font-family: 'Supercell';
  padding-top: 100px;
  font-size: 3rem;
}
