.toolbar {
    background: rgba(0, 0, 0, 0);
    width: 100%;
    height: 60px;
    position: relative;
    z-index: 100;
}

.toolbar-nav {
    display: flex;
    background: transparent;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: 0px;
}

.toolbar-logo {
    width: 250px;
    margin-left: 1rem;
}

.toolbar-logo img {
    width: 70%;
}

.toolbar-small-logo {
    flex: 1;
    text-align: center;
    z-index: 100;
}

.toolbar-small-logo img {
    height: 45px;
    /* Add negative margin based on SideDrawerButton width and left margin, to center the logo */
    margin-left: calc(-20px - .8rem);
    display: inline;
    z-index: 100;
}

.discord-toolbar-logo-container {
  width: 12%;
  margin-left: 1rem;
  margin-right: 1rem;
}

.discord-toolbar-logo {
  width: auto;
  height: auto;
}

.discord-toolbar-logo:hover {
  filter: none;
}

.toolbar-links ul {
    list-style: none;
    font-size: large;
    margin-right: 1rem;
    padding: 0;
    display: flex;
}

.toolbar-links li {
    padding: 0 2rem;
}

.toolbar-links a {
    text-decoration: none;
    font-family: 'Supercell';
    font-size: 0.9rem;
    color: rgb(255, 145, 0);
}

.toolbar-links a:hover,
.toolbar-links a:hover {
    color: rgb(116, 116, 116);
}

/* The container <div> - needed to position the dropdown content */
.dropdown-toolbar {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-toolbar-content {
  display: none;
  position: absolute;
  padding: 1rem 0;
  border-radius: 5px;
  text-align: center;
  background: linear-gradient(transparent, rgb(247 245 238 / 90%));
  width: 100%;
  box-shadow: 0px 8px 16px -15px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-toolbar-content li:hover {
  background-color: rgba(3, 3, 3, 0.2);

}

.dropdown-toolbar:hover .dropdown-toolbar-content {
  display: block;
}

.dropdown-toolbar:hover {
  background-color: transparent;
}

/* Links inside the dropdown */
.dropdown-toolbar-content a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* keeping it tidy */

.white {
  font-weight: bold;
  color: rgb(255, 255, 255);
  text-shadow: 0 0 1px black;
  font-size: 1rem;
}


@media (max-width: 1500px) {
    .toolbar-links li {
        padding: 0 2.0rem;
    }
}

@media (max-width: 1300px) {
    .toolbar-links li {
        padding: 0 1.8rem;
    }
}

@media (max-width: 1100px) {
    .toolbar-links li {
        padding: 0 1.3rem;
    }
    .toolbar-links a {
        font-size: 0.75rem;
    }
}

@media (min-width: 900px) {
    .toolbar-small-logo img {
        display: none;
    }
}

@media (max-width: 899px) {
    .toolbar-links {
        display: none;
    }
    .discord-toolbar-logo-container {
      display: none;
    }
    .toolbar-logo {
        display: none;
    }
}
