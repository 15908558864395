.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #202020;
  color: #d6d8de;
}

.footer-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 12% 6% 0% 6%;
}

.footer-content-container {
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 750px;
  padding: 0% 0% 5% 0%;
}

.footer-content-container-title {
  margin: 2.5% 0% 2% 0%;
  font-family: 'Supercell';
}

.footer-content-container-text {
  font-size: 0.8rem;
  padding: 0% 5% 0% 0%;
}

.footer-link-containers {
  display: flex;
  flex-wrap: nowrap;
  width: 40%;
}

.footer-link-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  padding: 0% 0% 0% 25%;
}

.footer-link-container-inner {

}

.footer-link-title {
  font-family: 'Supercell';
}

.list-unstyled {
  list-style-type: none;
  padding: 2% 0% 1% 0%;
}

.list-unstyled a {
  color: rgb(255, 145, 0);
}

.copyright {
    display: flex;
    justify-content: center;
    font-size: 1rem;
    margin: 1% 0% 4% 0%;
    width: 100%;
    color: rgb(195, 175, 165);
}
.copyright a {
    text-decoration: none;
}
.copyright a:hover {
    color: #458cde;
}


.social {
    display: flex;
    width: 25%;
    align-content: center;
    justify-content: space-evenly;
}

.social a {
    color: rgb(255, 145, 0);
    text-decoration: none;
}

.social a:hover {
    color: #468cde;
    transition: .2s;
}

.inline {
  display: inline-block;
}

@keyframes heartbeat {
  0% {
    transform: scale( .75);
  }
  20% {
    transform: scale( 1);
  }
  40% {
    transform: scale( .75);
  }
  60% {
    transform: scale( 1);
  }
  80% {
    transform: scale( .75);
  }
  100% {
    transform: scale( .75);
  }
}

#heart {
  position: relative;
  width: 12.5px;
  height: 11.25px;
  animation: heartbeat 1s infinite;
}

#heart:before,
#heart:after {
  position: absolute;
  content: "";
  left: 6.25px;
  top: 0;
  width: 6.25px;
  height: 10px;
  background: red;
  -moz-border-radius: 50px 50px 0 0;
  border-radius: 50px 50px 0 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

#heart:after {
  left: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

@media (max-width: 1400px) {
  .footer-content-container {
    width: 100%;
    align-items: center;
  }
  .footer-container {
    flex-wrap: wrap;
  }
  .footer-content-container-title {
    font-size: 0.8rem;
  }
  .footer-content-container-text {
    padding: 0% 8% 0% 8%;
    text-align: justify;
    font-size: 0.7rem;
  }
  .footer-link-containers {
    justify-content: center;
    width: 100%;
    max-width: 750px;
    padding: 0% 0% 0% 0%;
  }
  .footer-link-container {
    padding: 0% 0% 0% 0%;
    align-items: center;
  }
  .footer-link-title {
    font-size: 0.8rem;
  }
  .list-unstyled {
    font-size: 0.8rem;
  }
  .copyright {
    padding: 10% 0% 10% 0%;
  }
}

@media (max-width: 700px) {
    .copyright {
        justify-content: center;
        font-size: 0.6rem;
    }
    .copyright, .social {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 500px) {

}
