.showcase {
    background: rgb(0 14 43 / 70%);
    height: 100vh;
    width: 100%;
    color: #d6d8de;
}

.showcase-img {
    background: url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_035_PS0_1920w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
}

.showcase-darken {
    height: 100%;
    width: 100%;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: sshow-bg;
}

  @keyframes sshow-bg {
    from {
        background: rgba(0, 0, 0, 1);
    }

    to {
        background: rgba(0, 0, 0, 0.1);
    }
  }

.showcase-container-outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.showcase-container-middle {
  display: table-cell;
  vertical-align: middle;
}

.showcase-container-inner {
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  max-width: 600px;
  /*whatever width you want*/
}

.get-started {
  background: url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_017_part1_600w.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  margin: 5% 0% 0% 0%;
  border-radius: 10px;
  border: 2px solid #ff9100ad;
  font-size: 1.2rem;
  color: white;
  text-shadow: 2px 2px 6px #000000;
  font-family: 'Supercell';
  transition: .2s ease-out;
  cursor: pointer;
  -webkit-appearance: none;
  height: 140px;
  width: 100%;
  z-index: 3;
}

.get-started2 {
  background: url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_017_part2_600w.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  margin: 5% 0% 0% 0%;
  border-radius: 10px;
  border: 2px solid #ff9100ad;
  font-size: 1.2rem;
  color: white;
  text-shadow: 2px 2px 6px #000000;
  font-family: 'Supercell';
  transition: .2s ease-out;
  cursor: pointer;
  -webkit-appearance: none;
  height: 140px;
  width: 100%;
}

.get-started3 {
  background: url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_017_part3_600w.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  margin: 5% 0% 0% 0%;
  border-radius: 10px;
  border: 2px solid #ff9100ad;
  font-size: 1.2rem;
  color: white;
  text-shadow: 2px 2px 6px #000000;
  font-family: 'Supercell';
  transition: .2s ease-out;
  cursor: pointer;
  -webkit-appearance: none;
  height: 140px;
  width: 100%;
}

.get-started:hover, .get-started2:hover, .get-started3:hover {
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgb(254, 254, 254);
  transform: scale(1.2);
}


.link-container-text {
  background: url('https://assets.clashbases.de/Textsnipplets/Clashwallpaper_OnlyText_1120w.png') no-repeat;
  background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 38.4%;
    /* (img-height / img-width * container-width) - (430 / 1120 * 100)  */
}

.discord-link {
  text-decoration: none;
}


.baseShowcase-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgb(245 245 245);
}

.baseShowcase-container-top {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: 3px solid #ff9100;
  border-bottom: 3px solid #ff9100;
}

.column {
  width: 25%;
  padding: 0px;
}

.information-container {
    background: rgb(0 14 43 / 70%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Supercell';
    text-align: center;
    width: 100%;
    color: rgb(73, 73, 73);
}

.information-container-top {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 1rem 0 3rem 0;
    padding-top: 2rem;
}

.information-container-top .circle {
    display: inline-block;
    border-radius: 50%;
    padding: .8rem;
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 1.2rem;
}

.information-container-top .container-box {
    width: 250px;
    padding: 1rem;
    color: white;
    text-shadow: 0px 2px 5px #000000;
}

.container-box {
  font-variant-caps: small-caps;
}

.orange-color {
  color: rgb(255, 145, 0);
  text-shadow: 0px 2px 5px #000000;
}

.info-about {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 0% 5% 0% 5%;
  margin:8% 0% 2% 0%;
}

.info-about-title {
  font-size: 0.9rem;
  text-align: center;
  color: rgb(255, 145, 0);
  text-shadow: 0px 2px 5px #000000;
  padding-top: 20px;
  padding-bottom: 20px;
}

.info-about-text {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  font-size: 0.8rem;
  color: white;
  text-shadow: 0px 2px 5px #000000;
}


@media (max-width: 800px) {
    .baseShowcase-container-top {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .baseShowcase-container-top {
      align-items: center;
    }
}


@media (max-width: 1600px) {
    .showcase-img {
        background: url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_035_PS0_1600w.jpg') no-repeat;
        background-size: cover;
        background-position: center;
        height: 100%;
        width: 100%;
        background-size: cover;
    }
    .get-started {
      background: url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_017_part1_300w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
      height: 70px;
    }

    .get-started2 {
      background: url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_017_part2_300w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
      height: 70px;
    }

    .get-started3 {
      background: url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_017_part3_300w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
      height: 70px;
    }
    .showcase-container-inner {
      margin-left: auto;
      margin-right: auto;
      width: 75%;
      max-width: 300px;
      /*whatever width you want*/
    }

}

@media screen and (max-width: 1024px) {
  .showcase-img {
  background: url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_035_PS0_1200w.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  }
  .get-started, .get-started2, .get-started3 {
    font-size: 1.2rem;
  }
  .info-about-text {
    font-size: 0.7rem;
  }
}


@media (max-width: 640px) {
    .showcase-img {
      background: url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_035_PS0_850w.jpg') no-repeat;
      background-size: cover;
      background-position: top center;
    }
    .link-container-text {
      background: url('https://assets.clashbases.de/Textsnipplets/Clashwallpaper_OnlyText_400w.png') no-repeat;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;

    }
    .get-started, .get-started2, .get-started3 {
      font-size: 0.9rem;
    }
    .info-about-text {
      font-size: 0.6rem;
    }
}
