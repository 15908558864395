/* ABOUT PAGE */

.selection-about-page {
  background:
  linear-gradient(rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1)),
  url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_004_PS0_1920w.jpg');
  background-size: cover;
  background-position: center left;
  background-attachment: fixed;
  padding-top: 45px;
  height: 100vh;
}

.selection-about-page-container {
  padding-bottom: 10%;
  margin: auto;
}

.selection-container-about {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: auto;
  padding: 6% 7% 7% 7%;
  max-width: 1200px;
  height: auto;
}

.selection-container-townhall-selection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.selection-container-title {
  display: flex;
  width: 100%;
  height: 10%;
}

.selection-title {
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: 'Supercell';
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 3rem;
  color: rgb(255 145 0);
  text-shadow: 0px 2px #000000;
}

/* Townhall Button Containers */

.selection-container-outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.selection-container-middle {
  display: table-cell;
  vertical-align: middle;
}

.selection-container-inner {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  /*whatever width you want*/
}

.get-started-containers {
  width: 45%;
  margin: 3% auto 0% auto;
}

.get-started-townhalls {
  display: inline-block;
  padding: .9em .8em;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #34495e;
  color: white;
  font-size: 0.8rem;
  font-family: 'Supercell';
  background-color: rgba(57, 54, 56, 0.65);
  transition: .2s ease-out;
  cursor: pointer;
  -webkit-appearance: none;
  height: 9vh;
  width: 100%;
}

.get-started-townhalls:hover {
  background-color: rgba(52, 73, 94, 0.6);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgb(255, 204, 204);
  transform: scale(1.2);
}

.get-started-townhalls-15:hover {
  background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-15-cut.png');
  background-size: cover;
  background-position: top center;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgb(255, 204, 204);
  transform: scale(1.2);
}

.get-started-townhalls-14:hover {
  background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-14-cut.jpg');
  background-size: cover;
  background-position: top center;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgb(255, 204, 204);
  transform: scale(1.2);
}

.get-started-townhalls-13:hover {
  background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-13-cut.jpg');
  background-size: cover;
  background-position: top center;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgb(255, 204, 204);
  transform: scale(1.2);
}

.get-started-townhalls-12:hover {
  background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-12-cut.jpg');
  background-size: cover;
  background-position: top center;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgb(255, 204, 204);
  transform: scale(1.2);
}

.get-started-townhalls-11:hover {
  background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-11-cut.jpg');
  background-size: cover;
  background-position: top center;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgb(255, 204, 204);
  transform: scale(1.2);
}

.get-started-townhalls-10:hover {
  background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-10-cut.jpg');
  background-size: cover;
  background-position: top center;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgb(255, 204, 204);
  transform: scale(1.2);
}

.get-started-townhalls-9:hover {
  background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-9-cut.jpg');
  background-size: cover;
  background-position: top center;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgb(255, 204, 204);
  transform: scale(1.2);
}

.get-started-townhalls-8:hover {
  background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-8-cut.jpg');
  background-size: cover;
  background-position: top center;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgb(255, 204, 204);
  transform: scale(1.2);
}

.get-started-townhalls-7:hover {
  background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-7-cut.jpg');
  background-size: cover;
  background-position: top center;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgb(255, 204, 204);
  transform: scale(1.2);
}

.get-started-townhalls-6:hover {
  background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-6-cut.jpg');
  background-size: cover;
  background-position: top center;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgb(255, 204, 204);
  transform: scale(1.2);
}



@media (max-width: 1600px) {
    .selection-about-page {
        background: url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_004_PS0_1600w.jpg') no-repeat;
        background-size: cover;
        background-position: right center;
        background-size: cover;
    }

}

@media screen and (max-width: 1024px) {
  .selection-about-page {
      background: url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_004_PS0_1200w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
      background-size: cover;
  }
  .selection-container-inner {
    max-width: 500px;
    /*whatever width you want*/
  }
  .send-in-title {
    font-size: 1rem;
  }
  .selection-title {
    font-size: 1.2rem;
  }
  .get-started-townhalls {
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 800px) {
  .selection-container-about {
    padding: 10% 0% 7% 0%;
  }
  .selection-about-page {
   background-attachment: inherit;
  }
}

@media screen and (max-width: 640px) {
  .selection-about-page {
      background: url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_004_PS0_850w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
      background-size: cover;
  }
  .send-in-title {
    font-size: 0.7rem;
    line-height: normal;
  }
}

@media screen and (max-width: 450px) {
  .selection-title {
    font-size: 1rem;
  }
  .get-started-townhalls {
    font-size: 0.5rem;
  }
}
