.video-ad-container {
    display: block;
    position: relative;
    width: 640px;
    margin: 0 auto;
}

.video-height-pc {
    height: 400px;
}

@media only screen and (max-width: 800px) {
    .video-ad-container {
        display: block;
        width: 350px !important;
    }
}

.ad-container {
    display: flex;
    flex-wrap: wrap;
    min-height: 250px;
}

.ad-1 {
    display: flex;
    flex-wrap: wrap;
    height: 100px;
    width: 320px;
    border-width: 2px;
}

.ad-3 {
  display: flex;
  flex-wrap: wrap;
  height: 250px;
  width: 300px;
  border-width: 2px;
}

.ad-4 {
  display: flex;
  flex-wrap: wrap;
  height: 250px;
  width: 300px;
  border-width: 2px;
}


@media screen and (min-width: 768px) {
    .ad-1 {
      height: 90px;
      width: 728px;
    }
    .ad-container {
      height: 90px;
      width: 728px;
    }
    .ad-3 {
      height: 600px;
      width: 300px;
    }
    .ad-4 {
      display: none;
      height: 250px;
      width: 300px;
    }
  }
