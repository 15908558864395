.f-darken {
    height: 100%;
    width: 100%;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-name: fshow-bg;
}

  @keyframes fshow-bg {
    from {
        background: rgba(0, 0, 0, 1);
    }

    to {
        background: rgba(0, 0, 0, .00);
    }
  }

/* ADS */

.google-ads {
  display: flex;
  justify-content: center;
}

.ad-mobile-display {
  display: flex;
  justify-content: center;
  min-height: auto;
}

.Patreon-Ad-container {
  padding-top: 8px;
}

.ad-1 {
  display: flex;
  flex-wrap: wrap;
  height: 100px;
  width: 320px;
  border-width: 2px;
}

.ad-2 {
  display: flex;
  flex-wrap: wrap;
  height: 100px;
  width: 320px;
  border-width: 2px;
}

.ad-3 {
  display: flex;
  flex-wrap: wrap;
  height: 250px;
  width: 300px;
  border-width: 2px;
}

.ad-4 {
  display: flex;
  flex-wrap: wrap;
  height: 250px;
  width: 300px;
  border-width: 2px;
}

@media screen and (min-width: 768px) {
  .ad-1 {
    height: 90px;
    width: 728px;
  }
  .ad-2 {
    height: 90px;
    width: 728px;
  }
  .ad-3 {
    height: 600px;
    width: 300px;
  }
  .ad-4 {
    display: none;
    height: 250px;
    width: 300px;
  }
}

/* INDIVIDUAL COLOR */

.yellow {
  color: yellow;
}

/* SWITCH PAGE - WITH NUMBERS */


.page-button-container {
  display: flex;
  flex-wrap: nowrap;
  margin: auto;
  padding: 30px 8px 0 8px;
  width: 100%;
  align-items: center;
  max-width: 1200px;
}

.page-button {
  text-align: center;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #34495e;
  color: #34495e;
  font-size: 0.9rem;
  font-weight: bold;
  background-color: rgba(230, 230, 230, 0.5);
  transition: .2s ease-out;
  cursor: pointer;
  -webkit-appearance: none;
  width: 100%;
}

.page-button-current {
  text-align: center;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #34495e;
  color: rgb(230, 228, 222);
  font-size: 0.9rem;
  font-weight: bold;
  background-color: rgba(52, 73, 94, 0.6);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  transition: .2s ease-out;
  cursor: pointer;
  -webkit-appearance: none;
  width: 100%;
}

.page-button:hover {
  background-color: rgba(52, 73, 94, 0.6);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgb(255, 204, 204);
  transform: scale(1.2);
}

@media screen and (max-width: 800px) {
  .page-button, .page-button-current {
    padding: 5px;
    font-size: 0.6rem;
  }
  .page-button-container {
    padding: 15px 8px 0 8px;
  }
}

/* HOMEBASE PAGE */

.homebase-page {
  background:
  linear-gradient(rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1)),
  url('https://assets.clashbases.de/Backgrounds/grassybackground_1920w.jpg');
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
  padding-top: 60px;
}

@media screen and (max-width: 1600px) {
  .homebase-page {
    background:
    linear-gradient(rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1)),
    url('https://assets.clashbases.de/Backgrounds/grassybackground_1600w.jpg');
    background-size: cover;
    background-position: top center;
    background-attachment: fixed;
  }
}

@media screen and (max-width: 1200px) {
  .homebase-page {
    background:
    linear-gradient(rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1)),
    url('https://assets.clashbases.de/Backgrounds/grassybackground_1200w.jpg');
    background-size: cover;
    background-position: top center;
    background-attachment: fixed;
  }
}

@media screen and (max-width: 800px) {
  .homebase-page {
    background:
    linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
    url('https://assets.clashbases.de/Backgrounds/grassybackground-mobile_800w.jpg');
    background-size: contain;
  }
  .homebase-mobile-overlay {
    background:
    linear-gradient(rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1)),
    url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_031_PS0_800w.jpg');
    background-position: center;
    background-size: cover;
    padding-bottom: 10px;
  }

  @keyframes fshow-bg {
    from {
        background: rgba(0, 0, 0, .30);
    }

    to {
        background: rgba(0, 0, 0, .00);
    }
  }
}

@media screen and (max-width: 600px) {
  .homebase-page {
    background:
    linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
    url('https://assets.clashbases.de/Backgrounds/grassybackground-mobile_600w.jpg');
    background-size: contain;
  }
  .homebase-mobile-overlay {
    background:
    linear-gradient(rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1)),
    url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_031_PS0_600w.jpg');
  }
}

@media screen and (max-width: 350px) {
  .homebase-page {
    background:
    linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
    url('https://assets.clashbases.de/Backgrounds/grassybackground-mobile_350w.jpg');
    background-size: contain;
  }
  .homebase-mobile-overlay {
    background:
    linear-gradient(rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1)),
    url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_031_PS0_350w.jpg');
  }
}


.homebase-about-title {
  font-family: 'Supercell';
  width: 50%;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 3rem;
  color: rgb(255, 255, 255);
}

.homebase-title-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.homebase-paragraph-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: rgba(78, 78, 78, 0.6);
  margin: auto;
  margin-top: 5%;
  padding: 5% 7% 7% 7%;
  max-width: 1200px;
}

.homebase-paragraph-title {
  width: 100%;
  text-align: center;
  padding-left: 7%;
  padding-right: 7%;
  margin-top: 6%;
  font-family: 'Supercell';
  font-size: 1.2rem;
  color: rgb(255, 140, 0);
}

.homebase-paragraph {
  width: 100%;
  text-align: center;
  padding-left: 7%;
  padding-right: 7%;
  font-size: 1.2rem;
  color: rgb(255, 255, 255);
}

@media screen and (max-width: 1000px) {
  .homebase-paragraph-title {
    font-size: 1rem;
  }
  .homebase-paragraph {
    font-size: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .homebase-paragraph-container {
    margin-top: 10%;
    padding: 10% 2% 2% 2%;
  }
  .homebase-paragraph-title {
    font-size: 0.8rem;
  }
  .homebase-paragraph {
    font-size: 0.8rem;
  }
}

/* contributors */

.align-right {
  text-align: right;
}

/* SWITCH TO DIFFERENT TOWNHALL BUTTONS and Discord*/

.switch-container-th12 {
  display: flex;
  flex-wrap: nowrap;
  width: 50%;
  align-items: center;
}

.switch-buttons-th12 {
  text-align: center;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #34495e;
  color: #34495e;
  font-size: 1rem;
  font-variant-caps: all-small-caps;
  font-weight: bolder;
  background-color: rgba(230, 230, 230, 0.5);
  transition: .2s ease-out;
  cursor: pointer;
  -webkit-appearance: none;
  width: 100%;
}

.switch-to-th11-12:hover {
  background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-11-cut.jpg');
  background-size: cover;
  background-position: top center;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgb(255, 204, 204);
}

.switch-to-th12-12 {
  background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-12-cut.jpg');
  background-size: cover;
  background-position: top center;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgba(255, 204, 204, 0);
}

.switch-to-th13-12:hover {
  background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-13-cut.jpg');
  background-size: cover;
  background-position: top center;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgb(255, 204, 204);
}

.switch-to-th14-12:hover {
  background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-14-cut.jpg');
  background-size: cover;
  background-position: top center;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgb(255, 204, 204);
}

.switch-to-th15-12:hover {
  background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-15-cut.png');
  background-size: cover;
  background-position: top center;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgb(255, 204, 204);
}

.discord-link {
  margin-left: 7%;
  margin-right: 7%;
  text-decoration: none;
}

.discord-container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 80px;
  padding-bottom: 1%;
  padding-top: 1%;
  border: 1px solid #34495e;
  border-radius: 20px;
  background-color: rgba(57, 54, 56, 0.65);
  transition: transform .3s;
}

.discord-container:hover {
  transform: scale(1.2);
  background-color: rgba(63, 66, 73, 0.85);
}

.discord-logo-container {
  display: flex;
  flex-wrap: nowrap;
  width: 20%;
  object-fit: contain;
  transition: transform .3s;
}

.discord-logo-container:hover {
  transform: scale(1.4);
}

.discord-message-container {
  display: flex;
  flex-wrap: nowrap;
  padding-left: 25px;
  width: 80%;
  border-left: solid #7188d9;
}

.discord-message {
  display: flex;
  align-items: center;
  padding-right: 6%;
  font-size: 1rem;
  line-height: normal;
  color: white;
  transition: transform .3s;
}

.discord-message:hover {
  transform: scale(1.2);
}

.paypal-image {
  padding: 15px;
  object-fit: contain;
}

@media screen and (max-width: 1000px) {
  .homebase-about-title {
    font-size: 1rem;
  }
  .switch-buttons-th12 {
    font-size: 0.6rem;
  }
  .discord-message-container {
    padding-left: 3%;
    padding-right: 3%;
  }
  .discord-message {
    font-size: 0.9rem;
  }
  .discord-logo-container {
    padding: 0% 1% 0% 1%;
  }
}

@media screen and (max-width: 800px) {
  .homebase-title-container {
    -webkit-flex-flow: column-reverse;
    flex-flow: column-reverse;
  }
  .homebase-about-title {
    width: 100%;
    margin: 0;
    font-size: 1rem;
  }
  .switch-container-th12 {
    margin-top: 12px;
    width: 100%;
  }
  .switch-buttons-th12 {
    font-size: 0.6rem;
  }
  .discord-container {
    height: 60px;
    margin-left: 0%;
    margin-right: 0%;
    border-radius: 10px;
  }
  .discord-message {
    font-size: 0.7rem;
    line-height: normal;
  }
}


@media screen and (max-width: 1000px) {
  .homebase-about-title {
    font-size: 1rem;
  }
  .switch-buttons-th12 {
    font-size: 0.6rem;
  }
  .discord-message-container {
    padding-left: 3%;
    padding-right: 3%;
  }
  .discord-message {
    font-size: 0.9rem;
  }
  .discord-logo-container {
    padding: 0% 1% 0% 1%;
  }
}

@media screen and (max-width: 800px) {
  .homebase-title-container {
    -webkit-flex-flow: column-reverse;
    flex-flow: column-reverse;
  }
  .homebase-about-title {
    width: 100%;
    margin: 0;
    font-size: 1rem;
  }
  .switch-container-th12 {
    margin-top: 12px;
    width: 100%;
  }
  .switch-buttons-th12 {
    font-size: 0.6rem;
  }
  .discord-container {
    height: 60px;
    margin-left: 0%;
    margin-right: 0%;
    border-radius: 10px;
  }
  .discord-message {
    font-size: 0.7rem;
    line-height: normal;
  }
}

/**/

.link-color {
 color: rgb(31, 33, 33);
}

.filter-color {
 color: rgb(255, 255, 255);
}

.index-number {
  margin: auto;
}

 @media screen and (max-width: 1000px) {
   .about-title {
     font-size: 1.2rem;
   }
   .filter-label {
     font-size: 1.0rem;
     font-weigt: bold;
   }
 }

 @media screen and (max-width: 600px) {
   .filter-label {
     font-size: 0.7rem;
   }
 }

.my-shuffle-container-about {
  position: relative;
  overflow: hidden;
}

.my-sizer-element {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

$picture-gutter: 24px;
$item-height: 220px;
.picture-item {
  height: auto;
  margin-top: $picture-gutter;

  img {
    display: block;
    width: 100%;
  }
}

/* Having the height and fit in here fixes distortion of picture while zoomed */
img.medium-zoom-image {
  object-fit: cover;
  height: 100%;
}

@supports (object-fit: cover) {
  .picture-item img {
    max-width: none;
    object-fit: cover;
    height: 100%;
  }
}

.picture-item--h2 {
  height: ($item-height * 2) + $picture-gutter; /* 2x the height + 1 gutter */
}

.picture-item__inner {
  position: relative;
  height: 100%;
  overflow: hidden;
  background: rgba(232, 232, 232, 0.7);
}

.picture-item__details {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  padding: 0.6em;
  color: rgb(0, 0, 0);
}

.picture-item__description {
  width: 100%;
  padding: 0 2em 1em 1em;
  margin: 0;
}

.picture-item__title {
  flex-shrink: 0;
  margin-right: 4px;
}

.picture-item__tags {
  flex-shrink: 1;
  text-align: right;
  margin: 0;
}

/* Gallery, individual picture descriptions */

.font-size-picture-descriptions {
  font: Arial;
  font-size: 0.8em;
}

@media screen and (min-width: 768px) {
  .picture-item--overlay .picture-item__details {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: rgba(254, 254, 254, 0.35);
      backdrop-filter: blur(7px);
      color: white;
      overflow: hidden;
  }

  .picture-item__description {
      display: none;
    }
}


@media screen and (max-width: 767px) {
  .picture-item {
    height: auto;
    margin-top: 20px;
  }

  .picture-item__details,
  .picture-item__description {
    font-size: 0.875em;
    padding: 0.4em;
  }

  .picture-item__description {
    padding-right: 0.875em;
    padding-bottom: 1.25em;
  }

  .picture-item--h2 {
    height: auto;
  }
  .font-size-picture-descriptions {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 400px) {
  .font-size-picture-descriptions {
    font-size: 0.7em;
  }
}

/* Filter styling */

.filter-label {
  display: block;
  padding: 0;
  margin-top: 0;
  margin-bottom: 4px;
  font-weight: bold;
}

.filters-group {
  padding: 0;
  margin: 0 0 4px;
  border: 0;
}

@media screen and (min-width: 768px) {
  .filters-group-wrap {
    display: flex;
    justify-content: space-between;
  }
}
.btn-group:after,.btn-group:before {
  content: " ";
  display: table
}

.btn-group:after {
  clear: both
}

.btn-group .btn {
  float: left;
  border-radius: 0
}

.btn-group .btn:first-child {
  border-radius: 3px 0 0 3px
}

.btn-group .btn:not(:first-child) {
  margin-left: -1px
}

.btn-group .btn:last-child {
  border-radius: 0 3px 3px 0
}

.btn-group label.btn input[type=radio] {
  position: absolute;
  clip: rect(0,0,0,0);
  pointer-events: none;
}

.btn {
  display: inline-block;
  padding: .75em .8em;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #34495e;
  color: #34495e;
  font-size: 1rem;
  background-color: rgba(210, 210, 210, 0.95);
  transition: .2s ease-out;
  cursor: pointer;
  -webkit-appearance: none
}

@media (-moz-touch-enabled:0),(pointer: fine) {
  .btn:hover {
    color:#fff;
    text-decoration: none;
    background-color: #34495e;
  }
}

.btn:focus {
  outline-width: 0;
  box-shadow: 0 0 0 2px rgba(52,73,94,.4)
}

.btn.active,.btn:active {
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: #fff;
  background-color: #34495e
}

.btn:focus.active {
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3),0 0 0 2px rgba(52,73,94,.4)
}

.btn:disabled {
  cursor: not-allowed;
  opacity: .7;
  color: #34495e;
  background-color: rgba(52,73,94,0)
}

.btn--primary {
  font-weight: bold;
  color: #34495e;
  border-color: #34495e;
  background-color: rgba(210, 210, 210, 0.95);
}

@media (-moz-touch-enabled:0),(pointer: fine) {
  .btn--primary:hover {
    background-color: #34495e;
  }
}

.btn--primary:focus {
  box-shadow: 0 0 0 2px rgba(52,152,219,.4)
}

.btn--primary.active,.btn--primary:active {
  background-color: #34495e;
}

.btn--primary:focus.active {
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3),0 0 0 2px rgba(52,152,219,.4)
}

.btn--primary:disabled {
  color: #34495e;
  background-color: rgba(52,152,219,0)
}

@media screen and (max-width: 767px) {
  .btn {
    font-size: .7rem;
    padding: 0.4rem 0.5rem;
  }
}

/* Textfield */

.textfield {
  -webkit-appearance: none;
  box-sizing: border-box;
  width: 100%;
  border: 2px solid #95a5a6;
  border-radius: 4px;
  padding: 0;
  font-size: 1rem;
  color: #34495e;
  transition: .15s
}

.textfield::-webkit-input-placeholder {
  color: #95a5a6;
  transition: .15s
}

.textfield:-ms-input-placeholder {
  color: #95a5a6;
  transition: .15s
}

.textfield::-ms-input-placeholder {
  color: #95a5a6;
  transition: .15s
}

.textfield::placeholder {
  color: #95a5a6;
  transition: .15s
}

.textfield:hover {
  outline-width: 0;
  color: #5d6d77;
  border-color: #5d6d77
}

.textfield:hover::-webkit-input-placeholder {
  color: #5d6d77
}

.textfield:hover:-ms-input-placeholder {
  color: #5d6d77
}

.textfield:hover::-ms-input-placeholder {
  color: #5d6d77
}

.textfield:hover::placeholder {
  color: #5d6d77
}

.textfield:focus {
  outline-width: 0;
  border-color: #34495e
}

.textfield:focus::-webkit-input-placeholder {
  color: #34495e
}

.textfield:focus:-ms-input-placeholder {
  color: #34495e
}

.textfield:focus::-ms-input-placeholder {
  color: #34495e;
}

.textfield:focus::placeholder {
  color: #34495e;
}

@media screen and (max-width: 767px) {
  .textfield {
    width: 25%;
    padding: 0;
  }
}

// bootstrap3-like grid
// -----------

.container-about {
  padding-left: 3.5%;
  padding-right: 3.5%;
  padding-bottom: 1%;
}

.container-about:after,
.container-about:before {
  content: " ";
  display: table;
}

.container-about:after {
  clear: both;
}

.row-about {
  margin-left: auto;
  margin-right: auto;
}

.row-about:after,
.row-about:before {
  content: " ";
  display: table;
}

.row-about:after {
  clear: both;
}

.row-about .row-about {
  margin-left: -8px;
  margin-right: -8px;
}

/* Ensure images take up the same space when they load */
/* https://vestride.github.io/Shuffle/images */

.aspect {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
}

.aspect__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.aspect--16x9 {
  padding-bottom: 56.25%;
}

// bootstrap3-like grid

.col-1\@xs,
.col-2\@xs,
.col-3\@xs,
.col-4\@xs,
.col-5\@xs,
.col-6\@xs,
.col-1\@sm,
.col-2\@sm,
.col-3\@sm,
.col-4\@sm,
.col-5\@sm,
.col-6\@sm,
.col-7\@sm,
.col-8\@sm,
.col-9\@sm,
.col-10\@sm,
.col-11\@sm,
.col-12\@sm,
.col-1\@md,
.col-2\@md,
.col-3\@md,
.col-4\@md,
.col-5\@md,
.col-6\@md,
.col-7\@md,
.col-8\@md,
.col-9\@md,
.col-10\@md,
.col-11\@md,
.col-12\@md {
  position: relative;
  box-sizing: border-box;
  min-height: auto;
  padding-left: 8px;
  padding-right: 8px;
}

.col-1\@xs,
.col-2\@xs,
.col-3\@xs,
.col-4\@xs,
.col-5\@xs,
.col-6\@xs {
  float: left;
}

.col-1\@xs {
  width: 16.66667%;
}

.col-2\@xs {
  width: 33.33333%;
}

.col-3\@xs {
  width: 50%;
}

.col-4\@xs {
  width: 66.66667%;
}

.col-5\@xs {
  width: 83.33333%;
}

.col-6\@xs {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .col-1\@sm,
  .col-2\@sm,
  .col-3\@sm,
  .col-4\@sm,
  .col-5\@sm,
  .col-6\@sm,
  .col-7\@sm,
  .col-8\@sm,
  .col-9\@sm,
  .col-10\@sm,
  .col-11\@sm,
  .col-12\@sm {
    float: left;
  }

  .col-1\@sm {
    width: 8.33333%;
  }

  .col-2\@sm {
    width: 16.66667%;
  }

  .col-3\@sm {
    width: 25%;
  }

  .col-4\@sm {
    width: 33.33333%;
  }

  .col-5\@sm {
    width: 41.66667%;
  }

  .col-6\@sm {
    width: 50%;
  }

  .col-7\@sm {
    width: 58.33333%;
  }

  .col-8\@sm {
    width: 66.66667%;
  }

  .col-9\@sm {
    width: 75%;
  }

  .col-10\@sm {
    width: 83.33333%;
  }

  .col-11\@sm {
    width: 91.66667%;
  }

  .col-12\@sm {
    width: 100%;
  }

  .container-about {
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 1%;
  }

  .row-about {
    max-width: 1200px;
  }
}

@media screen and (min-width: 1024px) {
  .col-1\@md,
  .col-2\@md,
  .col-3\@md,
  .col-4\@md,
  .col-5\@md,
  .col-6\@md,
  .col-7\@md,
  .col-8\@md,
  .col-9\@md,
  .col-10\@md,
  .col-11\@md,
  .col-12\@md {
    float: left;
  }

  .col-1\@md {
    width: 8.33333%;
  }

  .col-2\@md {
    width: 16.66667%;
  }

  .col-3\@md {
    width: 25%;
  }

  .col-4\@md {
    width: 33.33333%;
  }

  .col-5\@md {
    width: 41.66667%;
  }

  .col-6\@md {
    width: 50%;
  }

  .col-7\@md {
    width: 58.33333%;
  }

  .col-8\@md {
    width: 66.66667%;
  }

  .col-9\@md {
    width: 75%;
  }

  .col-10\@md {
    width: 83.33333%;
  }

  .col-11\@md {
    width: 91.66667%;
  }

  .col-12\@md {
    width: 100%;
  }
}
