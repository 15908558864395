.menu-button {
    z-index: 401;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 28px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: .8rem;
    box-sizing: border-box;
    position: relative;
}

.menu-button:focus {
    outline: none;
}

.button-line {
    width: 35px;
    height: 4px;
    background: #ff9100;
}

@media (min-width: 900px) {
    .menu-button {
        display: none;
    }
    .swipebox {
        display: none;
    }
}

@-moz-document url-prefix() {
    .button-line {
        margin: 3px 0 3px 0;
    }
}
