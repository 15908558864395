html {
    height: 100%;
}

body {
    font-family: 'Raleway', sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
}

/* Small reset */
*,
::before,
::after {
  box-sizing: border-box;
}

figure {
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  color: #ff9100;
}

p {
  margin: 1em 0;
}

/* Fonts */

@font-face {
  font-family: 'Supercell';
  src: local('Supercell'), url(./fonts/supercell-magic-webfont.ttf) format('truetype');
  font-display: swap;
}

/* Page Loading */

.page-loading-outer {
  background: rgb(0 14 43 / 70%);
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.page-loading-middle {
  display: table-cell;
  vertical-align: middle;
}

.page-loading-inner {
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  max-width: 300px;
  /*whatever width you want*/
}

.page-loading-text {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Supercell';
  color: white;
  text-shadow: 0px 2px 5px #000000;
}

/* ScrollToTopButton */

.scroll-to-top {
  position: fixed;
  bottom: 2rem;
  right: 0rem;
  animation: fadeIn 1000ms ease-in-out 1s both;
  cursor: pointer;
}

.scroll-to-top:hover {
  opacity: 1;
  animation: wiggle 1s ease;
  animation-iteration-count: 1;
}

@keyframes wiggle {
  20% { transform: translateY(6px); }
  40% { transform: translateY(-6px); }
  60% { transform: translateY(4px); }
  80% { transform: translateY(-2px); }
  100% { transform: translateY(0); }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.scroll-container {
  background: rgb(255, 145, 0, 0.9);
  padding: 5px 0px 5px 2px;
  margin: 0px -3px 0px 0px;
  border-radius: 4px;

  -moz-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -ms-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

.scroll-text {
  margin: 0px 0px 0px 0px;
  writing-mode: vertical-rl;
  font-variant-caps: all-petite-caps;
  direction: rtl;
  word-spacing: -4px;
  color: rgba(255, 255, 255, 1);
  font-family: 'Supercell';
  text-shadow: 2px 2px 5px #000000;
}

/* SCROLL BARS */

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #0e2a38;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 150, 47, 1);
  border: 1px solid rgba(194, 96, 0, 1);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(194, 96, 0, 1);
}

/* OTHER STUFF */

.slide-open {
    position: relative;
    overflow: hidden;
    transition: 0.3s ease-out;
}

.slide-close {

  transition: 0.3s ease-out;
}

.slide-to-bottom {
    animation-name: show-down;
    animation-duration: .3s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    opacity: 0;
    will-change: transform;
}



@keyframes show-down {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (min-width: 900px) {
    .slide-to-right {
        animation-name: show-right;
        animation-duration: .5s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        opacity: 0;
        will-change: transform;
    }

    .slide-to-left {
        animation-name: show-left;
        animation-duration: .5s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        opacity: 0;
        will-change: transform;
    }

    .slide-to-top {
        animation-name: show-up;
        animation-duration: .3s;
        animation-timing-function: ease-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        opacity: 0;
        will-change: transform;
    }

    @keyframes show-up {
        0% {
            opacity: 0;
            transform: translateY(100%);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes show-right {
        0% {
            opacity: 0;
            transform: translateX(-100%);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes show-left {
        0% {
            opacity: 0;
            transform: translateX(100%);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
}

@media (max-width: 500px) {
    .slide-open {
        min-width: 200px;
        margin-left: 0;
    }
}
