.about-page {
  background:
  url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_034_PS0_1920w.jpg');
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
}

.about-container-outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.about-container-middle {
  display: table-cell;
  vertical-align: middle;
}

.about-container-inner {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1000px;
  /*whatever width you want*/
  background: rgba(0, 0, 0, .3);
}

.about-container-inner-c {
  margin-left: auto;
  margin-right: auto;
  padding: 5% 0% 5% 0%;
  height: 100%;
  width: 80%;
}

.orange-text {
  color: rgb(255, 145, 0);
}

.underline {
  text-decoration: underline;
}

.aboutMe-page {
  width: 100%;
  background: rgb(0 14 43 / 70%);
}

.aboutPrivacy-page {
  width: 100%;
  background:  rgb(0 14 43 / 70%);
}

.aboutMe-container-inner-c {
  margin-left: auto;
  margin-right: auto;
  padding: 5% 0% 5% 0%;
  height: 100%;
  width: 80%;
  max-width: 800px;
}

.about-w-title {
  margin-bottom: 8%;
  font-size: 1.3rem;
  font-family: 'Supercell';
  color: rgb(255, 145, 0);
  text-align: center;
  text-shadow: 0px 2px 5px #000000;
}

.aboutMe-w-title {
  margin-bottom: 5%;
  font-size: 1.3rem;
  font-family: 'Supercell';
  color: rgb(255, 145, 0);
  text-align: center;
  text-shadow: 0px 2px 5px #000000;
}

.about-p-title {
  margin-bottom: 2%;
  font-family: 'Supercell';
  color: rgb(255, 145, 0);
  text-align: center;
  text-shadow: 0px 2px 5px #000000;
  font-size: 1rem;
}

.about-w-text {
  font-size: 1.1rem;
  color: white;
  text-align: justify;
  text-shadow: 0px 2px 5px #000000;
}

.aboutMe-w-text {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
  font-size: 1.5rem;
  color: white;
  text-align: center;
  width: 80%;
  max-width: 800px;
  text-shadow: 0px 2px 5px #000000;
}

.about-p-text {
  font-size: 0.9rem;
  color: white;
  text-align: justify;

  text-shadow: 0px 2px 5px #000000;
}

.avatar-pic {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20%;
  background:
  url('https://assets.clashbases.de/Backgrounds/Personal_Avatar_PS0_1800w.png');
  background-size: cover;
  background-position: center;
  height: 45vh;
  width: 45vh;
}



@media screen and (max-width: 1600px) {
  .about-page {
    background:
    url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_034_PS0_1600w.jpg');
    background-size: cover;
    background-position: center;
  }
  .about-w-title {
    font-size: 1.2rem;
  }
  .about-w-text {
    font-size: 1rem;
  }
  .about-container-inner {
    max-width: 800px;
  }
  .aboutMe-w-title {
    font-size: 1.2rem;
  }
  .about-p-title {
    font-size: 0.8rem;
  }
  .aboutMe-w-text {
    font-size: 1rem;
  }
  .about-p-text {
    font-size: 0.7rem;
  }
  .aboutMe-container-inner {
    max-width: 800px;
  }
}

@media screen and (max-width: 800px) {
  .about-page {
    background:
    url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_034_PS0_1000w.jpg');
    background-size: cover;
    background-position: center;
  }
  .aboutMe-container-inner-c {
    padding: 15% 0% 15% 0%;
  }
  .about-w-title {
    font-size: 0.8rem;
  }
  .about-p-title {
    font-size: 0.65rem;
  }
  .about-w-text {
    font-size: 0.75rem;
  }
  .about-p-text {
    font-size: 0.6rem;
    margin-bottom: 0;
  }
  .aboutMe-w-title {
    font-size: 0.8rem;
    margin-bottom: 8%;
  }
  .aboutMe-w-text {
    font-size: 0.75rem;
    margin-bottom: 12%;
  }
  .avatar-pic {
    background:
    url('https://assets.clashbases.de/Backgrounds/Personal_Avatar_PS0_600w.png');
    background-size: cover;
    background-position: center;
    height: 35vh;
    width: 35vh;
  }
}

@media screen and (max-width: 500px) {
  .about-page {
    background:
    url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_034_PS0_700w.jpg');
    background-size: cover;
    background-position: center;
  }
  .about-w-title {
    font-size: 0.7rem;
  }
  .about-w-text {
    font-size: 0.65rem;
  }
  .about-p-text {
    font-size: 0.55rem;
  }
  .aboutMe-w-title {
    font-size: 0.7rem;
  }
  .about-p-title {
    font-size: 0.6rem;
  }
  .aboutMe-w-text {
    font-size: 0.65rem;
    width: 100%;
  }
  .avatar-pic {
    background:
    url('https://assets.clashbases.de/Backgrounds/Personal_Avatar_PS0_300w.png');
    background-size: cover;
    background-position: center;
    height: 30vh;
    width: 30vh;
  }
}

@media screen and (max-width: 350px) {
    .avatar-pic {
    background:
    url('https://assets.clashbases.de/Backgrounds/Personal_Avatar_PS0_300w.png');
    background-size: cover;
    background-position: center;
    height: 25vh;
    width: 25vh;
  }
}
