.side_drawer {
    height: 100%;
    background: url('https://assets.clashbases.de/Backgrounds/Clashwallpaper_012_PS0_Side.jpg');
    background-size: cover;
    background-position: center;
    position: fixed;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    font-family: 'Supercell';
      left: 0;
    width: 250px;
    z-index: 400;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    overflow: auto;
}

.side_drawer.open {
    transform: translateX(0);
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
}

.sidebar-menu {
  font-size: 0.8rem;
  padding-top: 15px;
  padding-bottom: 10px;
  color: rgb(255, 145, 0);
}

.sidebar-submenu {
  font-size: 0.6rem;
  padding-top: 1px;
  padding-bottom: 1px;
  color: rgb(255, 255, 255);
}

.side_drawer-menu {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
}

.side_drawer-menu li {
  display: flex;
  justify-content: center;
  padding: 3.5% 0% 5% 0%;
  text-shadow: 2px 2px 6px #000000;
  transition: .2s ease-out;
  list-style: none;
  cursor: pointer;
  -webkit-appearance: none;
}

.side_drawer-submenu li{
  padding: 3% 15%;
  font-size: 0.7rem;
}

.side_drawer-top .spacer {
  flex: 1;
}

.side_drawer-close {
  cursor: pointer;
}

.discord-sidebar-logo-container {
  width: 100%;
  margin-top: 10%;
}

.discord-sidebar-logo {
  width: 100%;
  height: auto;
  margin-top: 5%;
  filter: brightness(0) invert(1);
}

.discord-sidebar-logo:hover {
  filter: none;
  transform: scale(1.3);
}

@media (min-width: 900px) {
    .side_drawer {
        display: none;
    }
}

@media (max-width: 500px) {
    .side_drawer {
        width: 60%;
    }
}
