.wiki {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/236_ClashValley_1920w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    color: #d6d8de;
}

.wiki-home {
    background:
    linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0)),
    url('https://assets.clashbases.de/Wiki-Backgrounds/236_ClashValley_1920w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    color: #d6d8de;
}

.wiki-builder {
  background:
  linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
  url('https://assets.clashbases.de/Wiki-Backgrounds/236_ClashValley_1920w.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  color: #d6d8de;
}

.wiki-container-outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.wiki-container-middle {
  display: table-cell;
  vertical-align: middle;
}

.wiki-container-inner {
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 100%;
  max-width: 700px;
  /*whatever width you want*/
  padding: 2px;
}

.wiki-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  max-width: 700px;
  margin: 0 auto 0 auto;
}

.wiki-subcontainer {
  width: 100%;
  padding: 5px 10px 5px 10px;
  height: 100%;
}

.wiki-back {
  width: 100%;
  padding: 5px 10px 5px 10px;
  height: 10%;
}

.introHomebase-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 60px;
}

.introHomebase-subcontainer {
  width: 100%;
  padding: 5px 10px 5px 10px;
  height: 100%;
}

.introlink-1 {
  background: url('https://assets.clashbases.de/Wiki-Backgrounds/367_HomeVillage_1920w.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  border: 1px solid #ff9100ad;
  color: white;
  text-shadow: 2px 2px 6px #000000;
  font-size: 1.5rem;
  font-family: 'Supercell';
  transition: .2s ease-out;
  cursor: pointer;
  -webkit-appearance: none;
  height: 100%;
  width: 100%;
}

.introlink-2 {
  background: url('https://assets.clashbases.de/Wiki-Backgrounds/185_BuilderVillage_1920w.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  border: 1px solid #ff9100ad;
  color: white;
  text-shadow: 2px 2px 6px #000000;
  font-size: 1.5rem;
  font-family: 'Supercell';
  transition: .2s ease-out;
  cursor: pointer;
  -webkit-appearance: none;
  height: 100%;
  width: 100%;
}

.introlink-3 {
  background: url('https://assets.clashbases.de/Wiki-Backgrounds/381_WizardSchool_1920w.jpg') no-repeat;
  background-size: cover;
  background-position: bottom;
  border-radius: 10px;
  border: 1px solid #ff9100ad;
  color: white;
  text-shadow: 2px 2px 6px #000000;
  font-size: 1.5rem;
  font-family: 'Supercell';
  transition: .2s ease-out;
  cursor: pointer;
  -webkit-appearance: none;
  height: 100%;
  width: 100%;
}

.introlink-4 {
  background: url('https://assets.clashbases.de/Wiki-Backgrounds/228_ArcherTower_1920w.jpg') no-repeat;
  background-size: cover;
  background-position: bottom;
  border-radius: 10px;
  border: 1px solid #ff9100ad;
  color: white;
  text-shadow: 2px 2px 6px #000000;
  font-size: 1.5rem;
  font-family: 'Supercell';
  transition: .2s ease-out;
  cursor: pointer;
  -webkit-appearance: none;
  height: 100%;
  width: 100%;
}

.introlink-5 {
  background: url('https://assets.clashbases.de/Wiki-Backgrounds/389_SpellFight_1920w.jpg') no-repeat;
  background-size: cover;
  background-position: bottom;
  border-radius: 10px;
  border: 1px solid #ff9100ad;
  color: white;
  text-shadow: 2px 2px 6px #000000;
  font-size: 1.5rem;
  font-family: 'Supercell';
  transition: .2s ease-out;
  cursor: pointer;
  -webkit-appearance: none;
  height: 100%;
  width: 100%;
}

.introlink-6 {
  background: url('https://assets.clashbases.de/Wiki-Backgrounds/303_TroopConference_1920w.jpg') no-repeat;
  background-size: cover;
  background-position: bottom;
  border-radius: 10px;
  border: 1px solid #ff9100ad;
  color: white;
  text-shadow: 2px 2px 6px #000000;
  font-size: 1.5rem;
  font-family: 'Supercell';
  transition: .2s ease-out;
  cursor: pointer;
  -webkit-appearance: none;
  height: 100%;
  width: 100%;
}
.introlink-darken {
  background:
  linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6));
}

.introlink-7 {
  background: url('https://assets.clashbases.de/Wiki-Backgrounds/189_BuilderHall_1920w.jpg') no-repeat;
  background-size: cover;
  background-position: bottom;
  border-radius: 10px;
  border: 1px solid #ff9100ad;
  color: white;
  text-shadow: 2px 2px 6px #000000;
  font-size: 1.5rem;
  font-family: 'Supercell';
  transition: .2s ease-out;
  cursor: pointer;
  -webkit-appearance: none;
  height: 100%;
  width: 100%;
}

.introlink-8 {
  background: url('https://assets.clashbases.de/Wiki-Backgrounds/195_BuilderBarracks_1920w.jpg') no-repeat;
  background-size: cover;
  background-position: bottom;
  border-radius: 10px;
  border: 1px solid #ff9100ad;
  color: white;
  text-shadow: 2px 2px 6px #000000;
  font-size: 1.5rem;
  font-family: 'Supercell';
  transition: .2s ease-out;
  cursor: pointer;
  -webkit-appearance: none;
  height: 100%;
  width: 100%;
}

.introlink-back {
  background: rgb(0 0 0 / 20%);
  border-radius: 10px;
  padding: 0;
  border: 1px solid #ff9100ad;
  color: white;
  text-shadow: 2px 2px 6px #000000;
  font-size: 1.5rem;
  font-family: 'Supercell';
  transition: .2s ease-out;
  cursor: pointer;
  -webkit-appearance: none;
  height: 100%;
  width: 100%;
}

.introlink-1:hover, .introlink-2:hover, .introlink-3:hover, .introlink-4:hover, .introlink-5:hover, .introlink-6:hover, .introlink-7:hover, .introlink-8:hover, .introlink-back:hover {
  box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
  color: rgb(254, 254, 254);
  transform: scale(0.9);
}

.linebreak {
  display: none;
}


/* HOMEBASE WIKI - and subdomains */

/* Hometext */
.hometext-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgb(76 86 106);
    padding:2% 0% 2% 0%;
    border-bottom: 1px solid #ff9100ad;
}

.hometext-container-building {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgb(76 86 106);
    padding:5% 0% 15% 0%;
    border-bottom: 1px solid #ff9100ad;
}

.hometext-container-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgb(76 86 106);
    padding:20% 0% 20% 0%;
}

.hometext-container-top {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: 3px solid #ff9100;
  border-bottom: 3px solid #ff9100;
}

.hometext-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Supercell';
    text-align: center;
    width: 100%;
    color: rgb(73, 73, 73);
}

.hometext-about {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 0% 5% 0% 5%;
  margin:2% 0% 2s% 0%;
}

.hometext-about-title {
  font-size: 0.9rem;
  text-align: center;
  color: rgb(255, 145, 0);
  text-shadow: 0px 2px 1px #000000;
  padding-top: 20px;
  padding-bottom: 20px;
}

.hometext-about-text {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  font-size: 0.8rem;
  color: white;
  text-shadow: 0px 2px 5px #000000;
}

.orange-color {
  color: rgb(255, 145, 0);
  text-shadow: 0px 2px 5px #000000;
}

.hometext-column {
width: 25%;
padding: 0px;
}

/* Homebasewiki */
.homebasewiki-nav {
  background-color: var(--off-white);
  width: 100%;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}

.orange-underline {
  text-decoration: underline;
  text-decoration-thickness: 2.5px;
}

.homebasewiki-nav-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 80%;
  max-width: 350px;
  margin: -10px auto 20px auto;
}

.homebasewiki-nav-item {
  display: flex;
  justify-content: space-evenly;
  font-family: 'Supercell';
  font-size: 0.6rem;
  text-shadow: 0px 2px 5px #000000;
}

.homebasewiki-nav-item:hover {
  transform: scale(1.2);
}

.homebasewiki-nav-item a {
  color: white;
  text-decoration-color: #ff9100ad;
}

.homebasewiki-nav-item:hover {
  color: black;
  cursor: pointer;
}

.homebasewiki {
  height: 100%;
  width: 100%;
  background:
  linear-gradient(rgba(8, 35, 56, 0.3), rgba(8, 35, 56, 0.3)),
  url('https://assets.clashbases.de/Backgrounds/grassybackground-mobile_800w.jpg');
  background-size: contain;
  padding-top: 0px;
  padding-bottom: 20px;
}

.homebasewiki-b {
  height: 100%;
  width: 100%;
  background:
  linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
  url('https://assets.clashbases.de/Backgrounds/grassybackground-mobile_800w.jpg');
  background-size: contain;
  padding-top: 0px;
  padding-bottom: 20px;
}

.homebasewiki-text {
  height: 100%;
  width: 100%;
  background:
  linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
  url('https://assets.clashbases.de/Backgrounds/grassybackground-mobile_800w.jpg');
  background-size: contain;
}

.homebasewiki-container {
  height: 100%;
  padding-top: 40px;
}

.homebasewiki-buildings-container {
  height: 100%;
  width: 100%;
  padding-bottom: 30px;
  max-width: 700px;
  margin: 0 auto 0 auto;
}

.homebuildingsbackground {
  background: url('https://assets.clashbases.de/Wiki-Backgrounds/216_BuilderDone_1920w.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  border: 1px solid #ff9100ad;
  border-radius: 10px;
  height: 200px;
  width: 96%;
  max-width: 800px;
  margin: 0 auto 0 auto;
}

.basebuildingsbackground {
  background: url('https://assets.clashbases.de/Wiki-Backgrounds/192_BuilderAndOtto_1920w.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  border: 1px solid #ff9100ad;
  border-radius: 10px;
  height: 200px;
  width: 96%;
  max-width: 800px;
  margin: 0 auto 0 auto;
}

.homespellsbackground {
  background: url('https://assets.clashbases.de/Wiki-Backgrounds/281_ElixirSpill_1920w.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  border: 1px solid #ff9100ad;
  border-radius: 10px;
  height: 200px;
  width: 96%;
  max-width: 800px;
  margin: 0 auto 0 auto;
}

.hometroopsbackground {
  background: url('https://assets.clashbases.de/Wiki-Backgrounds/262_HogArmyPose_1920w.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  border: 1px solid #ff9100ad;
  border-radius: 10px;
  height: 200px;
  width: 96%;
  max-width: 800px;
  margin: 0 auto 0 auto;
}

.basetroopsbackground {
  background: url('https://assets.clashbases.de/Wiki-Backgrounds/345_BestFriends_1920w.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  border: 1px solid #ff9100ad;
  border-radius: 10px;
  height: 200px;
  width: 96%;
  max-width: 800px;
  margin: 0 auto 0 auto;
}

.homebasewiki-buildings-maintitel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  font-size: 1.5rem;
  font-family: 'Supercell';
  text-align: center;
  color: rgb(255, 145, 0);
  text-shadow: 0px 2px 5px #000000;
}

.homebasewiki-buildings-titel {
  margin-top: 50px;
  font-size: 1.3rem;
  font-family: 'Supercell';
  text-align: center;
  color: rgb(255, 145, 0);
  text-shadow: 0px 2px 5px #000000;
}

.homebasewiki-buildings-item-container {
  display: flex;
  flex-wrap: wrap;
  background: rgb(76 86 106);
  width: 96%;
  height: 60px;
  border: 1px solid #ff9100ad;
  border-radius: 5px;
  border-bottom: none;
  margin: 4% auto 0% auto;
}

.homebasewiki-buildings-item-container:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.homebasewiki-buildings-item-icon {
  width: 30%;
}

.homebasewiki-buildings-item-name {
  width: 70%;
  height: 60px;
  line-height: 60px;
  padding-left: 5%;
  font-size: 0.8rem;
  font-family: 'Supercell';
  color: white;
  text-shadow: 0px 2px 5px #000000;
}

.center-name {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.homebasewiki-buildings-item-detail-container {
  width: 96%;
  margin: 0% auto 0% auto;
  background:
  linear-gradient(rgb(76 86 106), rgb(131 175 200));
  border: 1px solid #ff9100ad;
  border-top: none;
  border-bottom: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 10px 15px 10px 15px;
  color: white;
  font-weight: bold;
  text-shadow: 0px 1px 3px #000000;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

.homebasewiki-buildings-item-table {
  text-align: center;
  border-collapse: collapse;
  width: 100%;
  margin-top: 15px;
}

.homebasewiki-buildings-item-table2 {
  text-align: center;
  border-collapse: collapse;
  margin-top: 20px;
  width: 100%;
}

.homebasewiki-buildings-item-table tr, .homebasewiki-buildings-item-table2 tr {
  width: 100%;
}

.homebasewiki-buildings-item-table th, .homebasewiki-buildings-item-table2 th {
  width: 5%;
  font-size: 1.1rem;
  white-space: nowrap;
}

.homebasewiki-buildings-item-table td, .homebasewiki-buildings-item-table2 td {
  border-bottom: 1px solid #ff9100ad;
  font-size: 0.7rem;
  padding: 1.5px;
  white-space: nowrap;
}

.orange-font {
  font-family: 'Supercell';
  color: #ff9100;
  text-shadow: 0px 1px 3px #000000;
}

/* Collapsible component */

.Collapsible {
  width: 100%;
}

.Collapsible__trigger {
  width: 100%;
}

/* MEDIA STYLES */

@media (max-width: 1400px) {
  .wiki {
      background: url('https://assets.clashbases.de/Wiki-Backgrounds/236_ClashValley_1600w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
  }

  .wiki-home {
      background:
      linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0)),
      url('https://assets.clashbases.de/Wiki-Backgrounds/236_ClashValley_1600w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
  }

  .wiki-builder {
    background:
    linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
    url('https://assets.clashbases.de/Wiki-Backgrounds/236_ClashValley_1600w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .introlink-1 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/367_HomeVillage_1600w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .introlink-2 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/185_BuilderVillage_1600w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .introlink-3 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/381_WizardSchool_1600w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-4 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/228_ArcherTower_1600w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-5 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/389_SpellFight_1600w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-6 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/303_TroopConference_1600w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-7 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/189_BuilderHall_1600w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-8 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/195_BuilderBarracks_1600w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .homebuildingsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/216_BuilderDone_1600w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .basebuildingsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/192_BuilderAndOtto_1600w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .homespellsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/281_ElixirSpill_1600w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .hometroopsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/262_HogArmyPose_1600w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .basetroopsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/345_BestFriends_1600w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 1024px) {
  .introlink-1, .introlink-2, .introlink-3, .introlink-4, .introlink-5, .introlink-6, .introlink-7, .introlink-8 {
    font-size: 0.9rem;
  }
  .wiki-container-inner {
    padding: 2px;
  }
  .hometext-about-text {
    font-size: 0.7rem;
  }
  .wiki {
      background: url('https://assets.clashbases.de/Wiki-Backgrounds/236_ClashValley_1200w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
  }

  .wiki-home {
      background:
      linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0)),
      url('https://assets.clashbases.de/Wiki-Backgrounds/236_ClashValley_1200w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
  }

  .wiki-builder {
    background:
    linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
    url('https://assets.clashbases.de/Wiki-Backgrounds/236_ClashValley_1200w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .introlink-1 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/367_HomeVillage_1200w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .introlink-2 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/185_BuilderVillage_1200w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .introlink-3 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/381_WizardSchool_1200w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-4 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/228_ArcherTower_1200w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-5 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/389_SpellFight_1200w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-6 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/303_TroopConference_1200w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-7 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/189_BuilderHall_1200w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-8 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/195_BuilderBarracks_1200w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .homebuildingsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/216_BuilderDone_1200w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .basebuildingsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/192_BuilderAndOtto_1200w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .homespellsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/281_ElixirSpill_1200w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .hometroopsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/262_HogArmyPose_1200w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .basetroopsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/345_BestFriends_1200w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 800px) {
    .homebasewiki-buildings-item-container:hover {
      transform: none;
    }
    .hometext-container-top {
        width: 100%;
    }
    .wiki {
        background: url('https://assets.clashbases.de/Wiki-Backgrounds/236_ClashValley_1024w.jpg') no-repeat;
        background-size: cover;
        background-position: center;
    }

    .wiki-home {
        background:
        linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0)),
        url('https://assets.clashbases.de/Wiki-Backgrounds/236_ClashValley_1024w.jpg') no-repeat;
        background-size: cover;
        background-position: center;
    }

    .wiki-builder {
      background:
      linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
      url('https://assets.clashbases.de/Wiki-Backgrounds/236_ClashValley_1024w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
    }

    .introlink-1 {
      background: url('https://assets.clashbases.de/Wiki-Backgrounds/367_HomeVillage_1024w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
    }

    .introlink-2 {
      background: url('https://assets.clashbases.de/Wiki-Backgrounds/185_BuilderVillage_1024w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
    }

    .introlink-3 {
      background: url('https://assets.clashbases.de/Wiki-Backgrounds/381_WizardSchool_1024w.jpg') no-repeat;
      background-size: cover;
      background-position: bottom;
    }

    .introlink-4 {
      background: url('https://assets.clashbases.de/Wiki-Backgrounds/228_ArcherTower_1024w.jpg') no-repeat;
      background-size: cover;
      background-position: bottom;
    }

    .introlink-5 {
      background: url('https://assets.clashbases.de/Wiki-Backgrounds/389_SpellFight_1024w.jpg') no-repeat;
      background-size: cover;
      background-position: bottom;
    }

    .introlink-6 {
      background: url('https://assets.clashbases.de/Wiki-Backgrounds/303_TroopConference_1024w.jpg') no-repeat;
      background-size: cover;
      background-position: bottom;
    }

    .introlink-7 {
      background: url('https://assets.clashbases.de/Wiki-Backgrounds/189_BuilderHall_1024w.jpg') no-repeat;
      background-size: cover;
      background-position: bottom;
    }

    .introlink-8 {
      background: url('https://assets.clashbases.de/Wiki-Backgrounds/195_BuilderBarracks_1024w.jpg') no-repeat;
      background-size: cover;
      background-position: bottom;
    }

    .homebuildingsbackground {
      background: url('https://assets.clashbases.de/Wiki-Backgrounds/216_BuilderDone_1024w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
    }

    .basebuildingsbackground {
      background: url('https://assets.clashbases.de/Wiki-Backgrounds/192_BuilderAndOtto_1024w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
    }

    .homespellsbackground {
      background: url('https://assets.clashbases.de/Wiki-Backgrounds/281_ElixirSpill_1024w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
    }

    .hometroopsbackground {
      background: url('https://assets.clashbases.de/Wiki-Backgrounds/262_HogArmyPose_1024w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
    }

    .basetroopsbackground {
      background: url('https://assets.clashbases.de/Wiki-Backgrounds/345_BestFriends_1024w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
    }
}

@media (max-width: 600px) {
  .introlink-1, .introlink-2, .introlink-3, .introlink-4, .introlink-5, .introlink-6, .introlink-7, .introlink-8, {
    font-size: 0.9rem;
  }
  .linebreak {
    display: inline;
  }
  .hometext-about-text {
    font-size: 0.6rem;
  }
  .hometext-container-top {
    align-items: center;
  }
  .homebasewiki-buildings-item-table th, .homebasewiki-buildings-item-table2 th {
    font-size: 0.8rem;
  }

  .homebasewiki-buildings-item-table td, .homebasewiki-buildings-item-table2 td {
    font-size: 0.55rem;
  }
  .wiki {
      background: url('https://assets.clashbases.de/Wiki-Backgrounds/236_ClashValley_800w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
  }

  .wiki-home {
      background:
      linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0)),
      url('https://assets.clashbases.de/Wiki-Backgrounds/236_ClashValley_800w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
  }

  .wiki-builder {
    background:
    linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
    url('https://assets.clashbases.de/Wiki-Backgrounds/236_ClashValley_800w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .introlink-1 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/367_HomeVillage_800w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .introlink-2 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/185_BuilderVillage_800w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .introlink-3 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/381_WizardSchool_800w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-4 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/228_ArcherTower_800w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-5 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/389_SpellFight_800w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-6 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/303_TroopConference_800w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-7 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/189_BuilderHall_800w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-8 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/195_BuilderBarracks_800w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .homebuildingsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/216_BuilderDone_800w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .basebuildingsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/192_BuilderAndOtto_800w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .homespellsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/281_ElixirSpill_800w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .hometroopsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/262_HogArmyPose_800w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .basetroopsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/345_BestFriends_800w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 350px) {
  .homebasewiki-buildings-item-table th, .homebasewiki-buildings-item-table2 th {
    font-size: 0.8rem;
  }

  .homebasewiki-buildings-item-table td, .homebasewiki-buildings-item-table2 td {
    font-size: 0.5rem;
  }
  .wiki {
      background: url('https://assets.clashbases.de/Wiki-Backgrounds/236_ClashValley_600w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
  }

  .wiki-home {
      background:
      linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0)),
      url('https://assets.clashbases.de/Wiki-Backgrounds/236_ClashValley_600w.jpg') no-repeat;
      background-size: cover;
      background-position: center;
  }

  .wiki-builder {
    background:
    linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
    url('https://assets.clashbases.de/Wiki-Backgrounds/236_ClashValley_600w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .introlink-1 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/367_HomeVillage_600w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .introlink-2 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/185_BuilderVillage_600w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .introlink-3 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/381_WizardSchool_600w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-4 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/228_ArcherTower_600w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-5 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/389_SpellFight_600w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-6 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/303_TroopConference_600w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-7 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/189_BuilderHall_600w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .introlink-8 {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/195_BuilderBarracks_600w.jpg') no-repeat;
    background-size: cover;
    background-position: bottom;
  }

  .homebuildingsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/216_BuilderDone_600w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .basebuildingsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/192_BuilderAndOtto_600w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .homespellsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/281_ElixirSpill_600w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .hometroopsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/262_HogArmyPose_600w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .basetroopsbackground {
    background: url('https://assets.clashbases.de/Wiki-Backgrounds/345_BestFriends_600w.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }
}

/* Asset Icons */

.townhall {
  background: url('https://assets.clashbases.de/Building-Icons/Ressource/Townhall/TownHall-lv14-5.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.clancastle {
  background: url('https://assets.clashbases.de/Building-Icons/Ressource/Clan-Castle/ClanCastle-lv10.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.elixircollector {
  background: url('https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Collector/ElixirCollector-lv14.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.elixirstorage {
  background: url('https://assets.clashbases.de/Building-Icons/Ressource/Elixir-Storage/ElixirStorage-lv15.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.goldmine {
  background: url('https://assets.clashbases.de/Building-Icons/Ressource/Gold-Mine/GoldMine-lv14.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.goldstorage {
  background: url('https://assets.clashbases.de/Building-Icons/Ressource/Gold-Storage/GoldStorage-lv15.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.darkelixirdrill {
  background: url('https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Drill/DarkElixirDrill-lv08.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.darkelixirstorage {
  background: url('https://assets.clashbases.de/Building-Icons/Ressource/Dark-Elixir-Storage/DarkElixirStorage-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.armycamp {
  background: url('https://assets.clashbases.de/Building-Icons/Army/Army-Camp/ArmyCamp-lv11.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.barracks {
  background: url('https://assets.clashbases.de/Building-Icons/Army/Barracks/Barracks-lv14.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.darkbarracks {
  background: url('https://assets.clashbases.de/Building-Icons/Ressource/Townhall/TownHall-lv14-5.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.laboratory {
  background: url('https://assets.clashbases.de/Building-Icons/Army/Laboratory/Laboratory-lv12.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.spellfactory {
  background: url('https://assets.clashbases.de/Building-Icons/Army/Spell-Factory/SpellFactory-lv06.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.darkspellfactory {
  background: url('https://assets.clashbases.de/Building-Icons/Army/Dark-Spell-Factory/DarkSpellFactory-lv05.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.workshop {
  background: url('https://assets.clashbases.de/Building-Icons/Army/Workshop/Workshop-lv05.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.pethouse {
  background: url('https://assets.clashbases.de/Building-Icons/Army/Pet-House/PetHouse-lv04.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.buildershut {
  background: url('https://assets.clashbases.de/Building-Icons/Defenses/Builders-Hut/BuildersHut-lv04-A.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.cannon {
  background: url('https://assets.clashbases.de/Building-Icons/Defenses/Canon/Cannon-lv20.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.archertower {
  background: url('https://assets.clashbases.de/Building-Icons/Defenses/Archer-Tower/ArcherTower-lv19.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.mortar {
  background: url('https://assets.clashbases.de/Building-Icons/Defenses/Mortar/Mortar-lv13.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.airdefense {
  background: url('https://assets.clashbases.de/Building-Icons/Defenses/Air-Defense/AirDefense-lv11.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.wizardtower {
  background: url('https://assets.clashbases.de/Building-Icons/Defenses/Wizard-Tower/WizardTower-lv13.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.airsweeper {
  background: url('https://assets.clashbases.de/Building-Icons/Defenses/Air-Sweeper/AirSweeper-lv07.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.hiddentesla {
  background: url('https://assets.clashbases.de/Building-Icons/Defenses/Hidden-Tesla/HiddenTesla-lv13.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.bombtower {
  background: url('https://assets.clashbases.de/Building-Icons/Defenses/Bomb-Tower/BombTower-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.xbow {
  background: url('https://assets.clashbases.de/Building-Icons/Defenses/X-Bow/XBow-lv08-G.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.infernotower {
  background: url('https://assets.clashbases.de/Building-Icons/Defenses/Inferno-Tower/InfernoTower-lv08-M.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.eagleartillery {
  background: url('https://assets.clashbases.de/Building-Icons/Defenses/Eagle-Artillery/EagleArtillery-lv05.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.scattershot {
  background: url('https://assets.clashbases.de/Building-Icons/Defenses/Scattershot/Scattershot-lv03.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.gigatesla {
  background: url('https://assets.clashbases.de/Building-Icons/Defenses/Giga-Tesla/GigaTesla-lv05.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.gigainferno13 {
  background: url('https://assets.clashbases.de/Building-Icons/Defenses/Giga-Inferno-13/GigaInferno13-lv05.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.gigainferno14 {
  background: url('https://assets.clashbases.de/Building-Icons/Defenses/Giga-Inferno-14/GigaInferno14-lv05.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.wall {
  background: url('https://assets.clashbases.de/Building-Icons/Defenses/Wall/Wall-lv15.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.bomb {
  background: url('https://assets.clashbases.de/Building-Icons/Traps/Bomb/Bomb-lv05-06.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.giantbomb {
  background: url('https://assets.clashbases.de/Building-Icons/Traps/Giant-Bomb/GiantBomb-lv07.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.springtrap {
  background: url('https://assets.clashbases.de/Building-Icons/Traps/Spring-Trap/SpringTrap-lv05.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.airbomb {
  background: url('https://assets.clashbases.de/Building-Icons/Traps/Air-Bomb/AirBomb-lv07-08.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.seekingairmine {
  background: url('https://assets.clashbases.de/Building-Icons/Traps/Seeking-Air-Mine/SeekingAirMine-lv03-04.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.skeletontrap {
  background: url('https://assets.clashbases.de/Building-Icons/Traps/Skeleton-Trap/SkeletonTrap-lv03-04-G.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.tornadotrap {
  background: url('https://assets.clashbases.de/Building-Icons/Traps/Tornado-Trap/TornadoTrap-lv02-03.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.lightningspell {
  background: url('https://assets.clashbases.de/Spell-Icons/LightningSpell.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.healingspell {
  background: url('https://assets.clashbases.de/Spell-Icons/HealingSpell.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.ragespell {
  background: url('https://assets.clashbases.de/Spell-Icons/RageSpell.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.jumpspell {
  background: url('https://assets.clashbases.de/Spell-Icons/JumpSpell.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.freezespell {
  background: url('https://assets.clashbases.de/Spell-Icons/FreezeSpell.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.clonespell {
  background: url('https://assets.clashbases.de/Spell-Icons/CloneSpell.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.invisibilityspell {
  background: url('https://assets.clashbases.de/Spell-Icons/InvisibilitySpell.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.poisonspell {
  background: url('https://assets.clashbases.de/Spell-Icons/PoisonSpell.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.earthquakespell {
  background: url('https://assets.clashbases.de/Spell-Icons/EarthquakeSpell.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.hastespell {
  background: url('https://assets.clashbases.de/Spell-Icons/HasteSpell.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.skeletonspell {
  background: url('https://assets.clashbases.de/Spell-Icons/SkeletonSpell.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.batspell {
  background: url('https://assets.clashbases.de/Spell-Icons/BatSpell.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

/* ELIXIR TROOPS */

.barbarian {
  background: url('https://assets.clashbases.de/Troop-Icons/Barbarian/Barbarian-lv10.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.archer {
  background: url('https://assets.clashbases.de/Troop-Icons/Archer/Archer-lv10.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.goblin {
  background: url('https://assets.clashbases.de/Troop-Icons/Goblin/Goblin-lv08.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.giant {
  background: url('https://assets.clashbases.de/Troop-Icons/Giant/Giant-lv10.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.wallbreaker {
  background: url('https://assets.clashbases.de/Troop-Icons/Wall-Breaker/WallBreaker-lv10.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.balloon {
  background: url('https://assets.clashbases.de/Troop-Icons/Balloon/Balloon-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.wizard {
  background: url('https://assets.clashbases.de/Troop-Icons/Wizard/Wizard-lv10.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.healer {
  background: url('https://assets.clashbases.de/Troop-Icons/Healer/Healer-lv07.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.dragon {
  background: url('https://assets.clashbases.de/Troop-Icons/Dragon/Dragon-lv08.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.pekka {
  background: url('https://assets.clashbases.de/Troop-Icons/Pekka/Pekka-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.babydragon {
  background: url('https://assets.clashbases.de/Troop-Icons/Baby-Dragon/BabyDragon-lv08.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.miner {
  background: url('https://assets.clashbases.de/Troop-Icons/Miner/Miner-lv07.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.electrodragon {
  background: url('https://assets.clashbases.de/Troop-Icons/Electro-Dragon/ElectroDragon-lv04.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.yeti {
  background: url('https://assets.clashbases.de/Troop-Icons/Yeti/Yeti-lv03.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

/* DARK TROOPS */

.minion {
  background: url('https://assets.clashbases.de/Troop-Icons/Minion/Headhunter-lv10.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.hogrider {
  background: url('https://assets.clashbases.de/Troop-Icons/Hog-Rider/HogRider-lv10.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.valkyrie {
  background: url('https://assets.clashbases.de/Troop-Icons/Valkyrie/Valkyrie-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.golem {
  background: url('https://assets.clashbases.de/Troop-Icons/Golem/Golem-lv10.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.witch {
  background: url('https://assets.clashbases.de/Troop-Icons/Witch/Witch-lv05.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.lavahound {
  background: url('https://assets.clashbases.de/Troop-Icons/Lava-Hound/LavaHound-lv06.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.lavapup {
  background: url('https://assets.clashbases.de/Troop-Icons/Lava-Hound/LavaPup-lv01.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.bowler {
  background: url('https://assets.clashbases.de/Troop-Icons/Bowler/Bowler-lv05.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.icegolem {
  background: url('https://assets.clashbases.de/Troop-Icons/Ice-Golem/IceGolem-lv05-06.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.headhunter {
  background: url('https://assets.clashbases.de/Troop-Icons/Headhunter/Headhunter-lv03.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

/* SUPER TROOPS */

.superbarbarian {
  background: url('https://assets.clashbases.de/Troop-Icons/Super-Barbarian/SuperBarbarian-lv08-10.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.superarcher {
  background: url('https://assets.clashbases.de/Troop-Icons/Super-Archer/SuperArcher-lv08-10.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.supergiant {
  background: url('https://assets.clashbases.de/Troop-Icons/Super-Giant/SuperGiant-lv09-10.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.sneakygoblin {
  background: url('https://assets.clashbases.de/Troop-Icons/Sneaky-Goblin/SneakyGoblin-lv07-08.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.superwallbreaker {
  background: url('https://assets.clashbases.de/Troop-Icons/Super-Wall-Breaker/SuperWallBreaker-lv07-10.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.superwizard {
  background: url('https://assets.clashbases.de/Troop-Icons/Super-Wizard/SuperWizard-lv09-10.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.infernodragon {
  background: url('https://assets.clashbases.de/Troop-Icons/Inferno-Dragon/InfernoDragon-lv06-08.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.superminion {
  background: url('https://assets.clashbases.de/Troop-Icons/Super-Minion/SuperMinion-lv08-10.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.supervalkyrie {
  background: url('https://assets.clashbases.de/Troop-Icons/Super-Valkyrie/SuperValkyrie-lv07-09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.superwitch {
  background: url('https://assets.clashbases.de/Troop-Icons/Super-Witch/SuperWitch-lv05.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.bigboy {
  background: url('https://assets.clashbases.de/Troop-Icons/Super-Witch/BigBoy-lv05.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.icehound {
  background: url('https://assets.clashbases.de/Troop-Icons/Ice-Hound/IceHound-lv05-06.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.icepup {
  background: url('https://assets.clashbases.de/Troop-Icons/Ice-Hound/IcePup-lv01.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

/* SIEGE MACHINES */

.wallwrecker {
  background: url('https://assets.clashbases.de/Troop-Icons/Siege-Icons/Wall-Wrecker/WallWrecker-lv04.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.battleblimp {
  background: url('https://assets.clashbases.de/Troop-Icons/Siege-Icons/Battle-Blimp/BattleBlimp-lv04.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.stoneslammer {
  background: url('https://assets.clashbases.de/Troop-Icons/Siege-Icons/Stone-Slammer/StoneSlammer-lv04.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.siegebarracks {
  background: url('https://assets.clashbases.de/Troop-Icons/Siege-Icons/Siege-Barracks/SiegeBarracks-lv04.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.loglauncher {
  background: url('https://assets.clashbases.de/Troop-Icons/Siege-Icons/Log-Launcher/LogLauncher-lv04.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

/* HEROES */

.barbarianking {
  background: url('https://assets.clashbases.de/Hero-Icons/Barbarian-King/Barbarian_King_3D_preview.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.archerqueen {
  background: url('https://assets.clashbases.de/Hero-Icons/Archer-Queen/Archer_Queen_3D_preview.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.grandwarden {
  background: url('https://assets.clashbases.de/Hero-Icons/Grand-Warden/Grand_Warden_3D_preview.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.royalchampion {
  background: url('https://assets.clashbases.de/Hero-Icons/Royal-Champion/Royal_Champion_3D_preview.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

/* PETS */

.lassi {
  background: url('https://assets.clashbases.de/Troop-Icons/Pet-Icons/LASSI_field.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.electroowl {
  background: url('https://assets.clashbases.de/Troop-Icons/Pet-Icons/Electro_Owl_field.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.mightyyak {
  background: url('https://assets.clashbases.de/Troop-Icons/Pet-Icons/Mighty_Yak_field.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.unicorn {
  background: url('https://assets.clashbases.de/Troop-Icons/Pet-Icons/Unicorn_field.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

/* Builder Buildings */

.b-builderhall {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Builder-Hall/BuilderHall-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-clocktower {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Clock-Tower/ClockTower-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-otto {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Otto/OttoHut-lv05.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-goldmine {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Gold-Mine/GoldMine-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-goldstorage {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Gold-Storage/GoldStorage-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-elixircollector {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Collector/ElixirCollector-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-elixirstorage {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Elixir-Storage/ElixirStorage-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-gemmine {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Gem-Mine/GemMine-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

/* Builder Buildings Army */

.b-builderbarracks {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Barracks/BuilderBarracks-lv11.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-starlaboratory {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Star-Laboratory/StarLaboratory-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-armycamp {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Army-Camp/ArmyCamp-lv01.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

/* Builder Buildings Army */

.b-cannon {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Cannon/Cannon-B-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-cannon {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Cannon/Cannon-B-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-doublecannon {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Double-Cannon/DoubleCannon-B-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-archertower {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Archer-Tower/ArcherTower-B-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-hiddentesla {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Hidden-Tesla/HiddenTesla-B-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-firecrackers {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Firecrackers/Firecrackers-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-crusher {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Crusher/Crusher-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-guardpost {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Guard-Post/GuardPost-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-airbombs {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Air-Bombs/AirBombs-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-multimortar {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Multi-Mortar/MultiMortar-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-roaster {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Roaster/Roaster-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-giantcannon {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Giant-Cannon/GiantCannon-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-megatesla {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Mega-Tesla/MegaTesla-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-lavalauncher {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Lava-Launcher/LavaLauncher-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-walls {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Wall/Wall-B-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

/* Builder Buildings Army */

.b-pushtrap {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Push-Trap/PushTrap-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-springtrap {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Spring-Trap/SpringTrap-lv04.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-mine {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Mine/Mine-G-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-megamine {
  background: url('https://assets.clashbases.de/Builder-Village/Buildings/Mega-Mine/MegaMine-G-lv09.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

/* Builder Troops */

.b-ragedbarbarian {
  background: url('https://assets.clashbases.de/Builder-Village/Troops/Raged-Barbarian/RagedBarbarian-lv17-18.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-sneakyarcher {
  background: url('https://assets.clashbases.de/Builder-Village/Troops/Sneaky-Archer/SneakyArcher-lv17-18.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-boxergiant {
  background: url('https://assets.clashbases.de/Builder-Village/Troops/Boxer-Giant/BoxerGiant-lv17-18.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-betaminion {
  background: url('https://assets.clashbases.de/Builder-Village/Troops/Beta-Minion/BetaMinion-lv17-18.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-bomber {
  background: url('https://assets.clashbases.de/Builder-Village/Troops/Bomber/Bomber-lv17-18.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-babydragon {
  background: url('https://assets.clashbases.de/Builder-Village/Troops/Baby-Dragon/BabyDragon-lv17-18.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-cannoncart {
  background: url('https://assets.clashbases.de/Builder-Village/Troops/Cannon-Cart/CannonCart-lv17-18.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-nightwitch {
  background: url('https://assets.clashbases.de/Builder-Village/Troops/Night-Witch/NightWitch-lv17-18.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-dropship {
  background: url('https://assets.clashbases.de/Builder-Village/Troops/Drop-Ship/DropShip-lv17-18.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-superpekka {
  background: url('https://assets.clashbases.de/Builder-Village/Troops/Super-Pekka/SuperPekka-lv17-18.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.b-hogglider {
  background: url('https://assets.clashbases.de/Builder-Village/Troops/Hog-Glider/HogGlider-lv13-18.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}

.battlemachine {
  background: url('https://assets.clashbases.de/Builder-Village/Troops/Battle-Machine/BattleMachine-lv30.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: 85%;
  margin: auto 0 auto 0;
}
