/* ABOUT PAGE */

 .th6-about-page {
   background:
   linear-gradient(rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1)),
   url('https://assets.clashbases.de/Backgrounds/grassybackground_1920w.jpg');
   background-size: cover;
   background-position: top center;
   background-attachment: fixed;
   padding-top: 60px;
 }

 @media screen and (max-width: 1600px) {
  .th6-about-page {
    background:
    linear-gradient(rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1)),
    url('https://assets.clashbases.de/Backgrounds/grassybackground_1600w.jpg');
    background-size: cover;
    background-position: top center;
    background-attachment: fixed;
  }
 }

 @media screen and (max-width: 1200px) {
  .th6-about-page {
    background:
    linear-gradient(rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1)),
    url('https://assets.clashbases.de/Backgrounds/grassybackground_1200w.jpg');
    background-size: cover;
    background-position: top center;
    background-attachment: fixed;
  }
 }

 @media screen and (max-width: 800px) {
  .th6-about-page {
    background:
    linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
    url('https://assets.clashbases.de/Backgrounds/grassybackground-mobile_800w.jpg');
    background-size: contain;
  }

  @keyframes fshow-bg {
    from {
        background: rgba(0, 0, 0, .30);
    }

    to {
        background: rgba(0, 0, 0, .00);
    }
  }
 }

 @media screen and (max-width: 600px) {
  .th6-about-page {
    background:
    linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
    url('https://assets.clashbases.de/Backgrounds/grassybackground-mobile_600w.jpg');
    background-size: contain;
  }
 }

 @media screen and (max-width: 350px) {
  .th6-about-page {
    background:
    linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
    url('https://assets.clashbases.de/Backgrounds/grassybackground-mobile_350w.jpg');
    background-size: contain;
  }
 }

 .th6-about-title {
   width: 50%;
   font-family: 'Supercell';
   font-weight: 700;
   font-size: 1.4rem;
   line-height: 3rem;
   color: rgb(255, 255, 255);
 }

 .th6-filter-color {
   color: rgb(255, 255, 255);
 }

 .th6-title-container {
   display: -webkit-box;
   display: -moz-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   width: 100%;
 }

 /* SWITCH TO DIFFERENT TOWNHALL BUTTONS */

 .switch-container-th6 {
   display: flex;
   flex-wrap: nowrap;
   width: 50%;
   align-items: center;
 }

 .switch-buttons-th6 {
   text-align: center;
   padding: 10px;
   border-radius: 3px;
   border: 1px solid #34495e;
   color: #34495e;
   font-size: 1rem;
   font-variant-caps: all-small-caps;
   font-weight: bolder;
   background-color: rgba(230, 230, 230, 0.5);
   transition: .2s ease-out;
   cursor: pointer;
   -webkit-appearance: none;
   width: 100%;
 }

 .switch-to-th6-6:hover {
   background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-6-cut.jpg');
   background-size: cover;
   background-position: top center;
   box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
   color: rgb(255, 204, 204);
 }

  .switch-to-th6-6 {
   background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-6-cut.jpg');
   background-size: cover;
   background-position: top center;
   box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
   color: rgba(255, 204, 204, 0);
 }

 .switch-to-th7-6:hover {
   background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-7-cut.jpg');
   background-size: cover;
   background-position: top center;
   box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
   color: rgb(255, 204, 204);
 }

 .switch-to-th8-6:hover {
   background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-8-cut.jpg');
   background-size: cover;
   background-position: top center;
   box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
   color: rgb(255, 204, 204);
 }

 .switch-to-th9-6:hover {
   background: url('https://assets.clashbases.de/Townhall-Icons/town-hall-9-cut.jpg');
   background-size: cover;
   background-position: top center;
   box-shadow: inset 0 1px 2px rgba(0,0,0,.3);
   color: rgb(255, 204, 204);
 }

 .alternate-switch-buttons-text-th6 {
   display: none;
 }

 .alternate-switch-buttons-text-th6-temporary {
   display: none;
 }

 @media screen and (max-width: 1000px) {
   .th6-about-title {
     font-size: 1rem;
   }
   .switch-buttons-th6 {
     font-size: 0.6rem;
   }
 }

 @media screen and (max-width: 800px) {
   .th6-title-container {
     -webkit-flex-flow: column-reverse;
     flex-flow: column-reverse;
   }
   .th6-about-title {
     width: 100%;
     margin: 0;
     font-size: 0.8rem;
   }
   .switch-container-th6 {
     margin-top: 12px;
     width: 100%;
   }
   .switch-buttons-th6 {
     font-size: 0.6rem;
   }
 }

 /**/

 @media screen and (max-width: 900px) {
   .th6-about-title {
     font-size: 1rem;
   }
   .th6-filter-label {
     font-size: 1.0rem;
   }
 }

 @media screen and (max-width: 600px) {
   .th6-filter-label {
     font-size: 0.7rem;
   }
 }

.my-shuffle-th6-container-about {
  position: relative;
  overflow: hidden;
}

/* Filter styling */

.th6-filter-label {
  display: block;
  padding: 0;
  margin-top: 0;
  margin-bottom: 4px;
  font-weight: bold
}

.th6-filters-group {
  padding: 0;
  margin: 0 0 4px;
  border: 0;
}

@media screen and (min-width: 768px) {
  .th6-filters-group-wrap {
    display: flex;
    justify-content: space-between;
  }
}

// bootstrap3-like grid
// -----------

.th6-container-about {
  padding-left: 3.5%;
  padding-right: 3.5%;
  padding-bottom: 1%;
}

.th6-container-about:after,
.th6-container-about:before {
  content: " ";
  display: table;
}

.th6-container-about:after {
  clear: both;
}

.th6-row-about {
  margin-left: auto;
  margin-right: auto;
}

.th6-row-about:after,
.th6-row-about:before {
  content: " ";
  display: table;
}

.th6-row-about:after {
  clear: both;
}

.th6-row-about .th6-row-about {
  margin-left: -8px;
  margin-right: -8px;
}

  // bootstrap3-like grid

  @media screen and (min-width: 768px) {
    .th6-container-about {
      padding-left: 7%;
      padding-right: 7%;
      padding-bottom: 1%;
    }

    .th6-row-about {
      max-width: 1200px;
    }

  }
