/* ABOUT PAGE */

 .builder-about-page {
   background:
   linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
   url('https://assets.clashbases.de/Backgrounds/grassybackground_1920w.jpg');
   background-size: cover;
   background-position: top center;
   background-attachment: fixed;
   padding-top: 60px;
 }

 @media screen and (max-width: 1600px) {
  .builder-about-page {
    background:
    linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
    url('https://assets.clashbases.de/Backgrounds/grassybackground_1600w.jpg');
    background-size: cover;
    background-position: top center;
    background-attachment: fixed;
  }
 }

 @media screen and (max-width: 1200px) {
  .builder-about-page {
    background:
    linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
    url('https://assets.clashbases.de/Backgrounds/grassybackground_1200w.jpg');
    background-size: cover;
    background-position: top center;
    background-attachment: fixed;
  }
 }

 @media screen and (max-width: 800px) {
  .builder-about-page {
    background:
    linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
    url('https://assets.clashbases.de/Backgrounds/grassybackground-mobile_800w.jpg');
    background-size: contain;
  }

  @keyframes fshow-bg {
    from {
        background: rgba(0, 0, 0, .30);
    }

    to {
        background: rgba(0, 0, 0, .00);
    }
  }
 }

 @media screen and (max-width: 600px) {
  .builder-about-page {
    background:
    linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
    url('https://assets.clashbases.de/Backgrounds/grassybackground-mobile_600w.jpg');
    background-size: contain;
  }
 }

 @media screen and (max-width: 350px) {
  .builder-about-page {
    background:
    linear-gradient(rgba(8, 35, 56, 0.6), rgba(8, 35, 56, 0.6)),
    url('https://assets.clashbases.de/Backgrounds/grassybackground-mobile_350w.jpg');
    background-size: contain;
  }
 }

 .builder-about-title {
   width: 100%;
   font-family: 'Supercell';
   font-weight: 700;
   font-size: 1.4rem;
   line-height: 3rem;
   color: rgb(255, 255, 255);
 }

 @media screen and (max-width: 1000px) {
   .builder-about-title {
     font-size: 1rem;
   }
 }

 .builder-filter-color {
   color: rgb(255, 255, 255);
 }

 @media screen and (max-width: 900px) {
   .builder-about-title {
     font-size: 1rem;
   }
   .builder-filter-label {
     font-size: 1.0rem;
   }
 }

 @media screen and (max-width: 600px) {
   .builder-about-title {
     font-size: 0.8rem;
   }
   .builder-filter-label {
     font-size: 0.8rem;
   }
 }

.my-shuffle-builder-container-about {
  position: relative;
  overflow: hidden;
}

/* Filter styling */

.builder-filter-label {
  display: block;
  padding: 0;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 4px;
}

.builder-filters-group {
  padding: 0;
  margin: 0 0 4px;
  border: 0;
}


@media screen and (min-width: 768px) {
  .builder-filters-group-wrap {
    display: flex;
    justify-content: space-between;
  }
}

// bootstrap3-like grid
// -----------

.builder-container-about {
  padding-left: 3.5%;
  padding-right: 3.5%;
  padding-bottom: 2%;
}

.builder-container-about:after,
.builder-container-about:before {
  content: " ";
  display: table;
}

.builder-container-about:after {
  clear: both;
}

.builder-row-about {
  margin-left: auto;
  margin-right: auto;
}

.builder-row-about:after,
.builder-row-about:before {
  content: " ";
  display: table;
}

.builder-row-about:after {
  clear: both;
}

.builder-row-about .builder-row-about {
  margin-left: -8px;
  margin-right: -8px;
}

@media screen and (min-width: 768px) {
  .builder-container-about {
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 1%;
  }

  .builder-row-about {
    max-width: 1200px;
  }
}
